import React from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonButton,
} from "@ionic/react";

const ProfileRouteItem: React.FC<{
  route: {
    id: number,
    from: string,
    to: string,
  },
  deleteRoute: (index: number) => void
}> = ({ route, deleteRoute }) => {

  return (
    <IonItem key={route.id} className='routeItem' lines='full'>
      <IonGrid className='ion-no-padding'>
        <IonRow className='ion-justify-content-between'>
          <IonCol size='auto'>
            <IonLabel position='stacked' color='primary'>Route {route.id + 1}</IonLabel>
          </IonCol>
          <IonCol size='auto'>
            <IonButton onClick={() => console.log('Clicked edit route')}>Edit</IonButton>
            <IonButton onClick={() => deleteRoute(route.id)}>x</IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <p>
            <strong>From</strong>: {route.from}<br /><br />
            <strong>To</strong>: {route.to}
          </p>
        </IonRow>
      </IonGrid>
    </IonItem >
  );

};

export default ProfileRouteItem;