import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonInput, IonItem, IonList, IonPage, IonText, IonTitle, IonToolbar } from "@ionic/react"
import { useEffect, useRef } from "react"
import { ExtendedFirestoreInstance } from "react-redux-firebase"
import { Order } from "../data/order"
import { toast } from "../toast"

interface orderChatState {
    dismiss: () => void,
    messages: any
    order: {
        id: string,
        data: Order
    },
    current_id: string,
    firestore: ExtendedFirestoreInstance
}

const OrderChat: React.FC<orderChatState> = ({
    dismiss,
    messages,
    order,
    current_id,
    firestore
}) => {

    useEffect(() => {}, [messages])

    const chatInput = useRef('')

    const sendMessage = () => {
        if (chatInput.current.length !== 0) {
            firestore.collection('orders').doc(order.id).collection('messages')
            .add({
                createdAt: firestore.FieldValue.serverTimestamp(),
                from: current_id,
                message: chatInput.current
            })
            .then(() => {
                chatInput.current = ''
                
            })
            .catch(err => toast(`${err}`, 3000))
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonItem lines='none'>
                        <IonButtons>
                            <IonButton 
                                color="danger"
                                fill='solid'
                                shape='round'
                                onClick={() => dismiss()}
                                size='small'
                            >Back</IonButton>
                        </IonButtons>
                        <IonTitle>
                            <IonText>Chatting with {current_id === order.data.creatorId ? "carrier" : "Sender"}</IonText><br />
                        </IonTitle>
                    </IonItem>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    {
                        (() => {
                            if (messages) {
                                return Object.keys(messages).map((key: string, index) => {
                                    let data = messages[key]
                                    if (current_id === data.from)
                                        return (
                                            <IonItem key={index} color='primary' style={{
                                                borderRadius: "1.2em",
                                                width: "max-content",
                                                maxWidth: "60%",
                                                margin: ".5em .5em 0 auto"
                                            }}>
                                                <IonText slot='start' style={{
                                                    padding: "5px",
                                                    margin: "0"
                                                }}>{data.message}</IonText>
                                            </IonItem>
                                        )
                                    else
                                        return (
                                            <IonItem key={index} color='medium' style={{
                                                borderRadius: "1.2em",
                                                width: "max-content",
                                                maxWidth: "60%",
                                                margin: ".5em auto 0 .5em",
                                            }}>
                                                <IonText slot='start' style={{
                                                    margin: "0",
                                                    padding: "5px"
                                                }}>{data.message}</IonText>
                                            </IonItem>
                                        )
                                })
                            }
                            else {
                                return <IonItem lines="none" style={{ margin: "auto",  }}><h3>Send the first message</h3></IonItem>
                            }
                        })()
                    }
                </IonList>
            </IonContent>
            {
                (() => {
                    if ( !(order.data.status === 'completed' && order.data.process === 'done')) {
                        return (
                            <IonFooter>
                                <IonToolbar>
                                    <IonItem lines='none' style={{margin: "0"}}>
                                        <IonInput type='text' value='' className=''
                                            onIonChange={e => chatInput.current = e.detail.value!}
                                            style={{
                                                backgroundColor: "lightgray",
                                                borderRadius: "1.2em",
                                                marginRight: "5px"
                                            }}
                                        />
                                        <IonButton color='primary' size='default' shape='round' onClick={() => sendMessage()}>Send</IonButton>
                                    </IonItem>
                                </IonToolbar>
                            </IonFooter>
                        )
                    }
                })()
            }
        </IonPage>
    )
}

export default OrderChat