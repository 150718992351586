import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
// import 'firebase/compat/database'

import { createStore } from 'redux'
import { actionTypes, createFirestoreInstance } from 'redux-firestore'

import { initialState, rootReducer } from './reducer'
import { Profile } from '../data/profile'


const firebaseConfig = {
  apiKey: "AIzaSyDyLe5xcRr3x_xjtq0_2HMWGeBko3Qy9G4",
  authDomain: "envoaid-test.firebaseapp.com",
  projectId: "envoaid-test",
  storageBucket: "envoaid-test.appspot.com",
  messagingSenderId: "533031073445",
  appId: "1:533031073445:web:00c54e586b6941ab883f4c"
  // measurementId: "G-PNH3MFJX8G",
  // databaseURL: 'https://transport-mobile-app-default-rtdb.europe-west1.firebasedatabase.app/'
}

// REACT_APP_FIREBASE_API_KEY=AIzaSyDyLe5xcRr3x_xjtq0_2HMWGeBko3Qy9G4
// REACT_APP_FIREBASE_AUTH_DOMAIN=envoaid-test.firebaseapp.com
// REACT_APP_FIREBASE_PROJECT_ID=envoaid-test
// REACT_APP_FIREBASE_STORAGE_BUCKET=envoaid-test.appspot.com
// REACT_APP_FIREBASE_MESSAGING_SENDER_ID=533031073445
// REACT_APP_FIREBASE_APP_ID=1:533031073445:web:00c54e586b6941ab883f4c


firebase.initializeApp(firebaseConfig)
firebase.auth()
firebase.firestore()

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  profileFactory: (userData: any, profileData: any, firebase: any): Profile => {
    const { user } = userData
    return new Profile(user.email, user.firstName, user.lastName, user.userName, user.phoneNumber).data()
  },
  onAuthStateChanged: (authData: any, firebase: any, dispatch: any) => {
    if (!authData) {
      dispatch({ type: actionTypes.CLEAR_DATA })
    }
  }
}


const store = createStore(rootReducer, initialState)

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}


const setup = {
  store,
  rrfProps,
  firebase
}

export default setup