import { IonButton, IonContent, IonFooter, IonItem, IonList, IonToolbar } from "@ionic/react";
import { ExtendedFirestoreInstance } from "react-redux-firebase";
import FeedbackItem from "../../../../components/FeedbackItem";
import { PresentCarrier } from "../../../../data/presentCarrier";
import { toast } from "../../../../toast";

const CarrierItem: React.FC<{
    carrier: PresentCarrier,
    onDismiss: () => void,
    onComplete: () => void,
    orderId: string,
    firestore: ExtendedFirestoreInstance
}> = ({
    carrier,
    onDismiss,
    onComplete,
    orderId,
    firestore
}) => {

        const handleCarrierSelect = () => {
            firestore.collection('orders').doc(orderId).update({
                carrierId: carrier.id
            })
                .then(() => {
                    firestore.collection('users').doc(carrier.id).update({
                        orders: firestore.FieldValue.arrayUnion(orderId),
                    })
                })
                .then(() => {
                    toast("Notification is sent to the carrier");
                    onComplete()
                })
        }

        return (
            <>
                <IonContent className="modalItem">
                    <IonItem>
                        {

                            carrier.displayName

                        }
                    </IonItem>
                    <IonItem>
                        Reputation:{" "}
                        {

                            carrier.reputation * 10

                        }
                        /10
                    </IonItem>
                    <IonItem>
                        Price:
                        {

                            carrier.price

                        }{" "}
                        €
                    </IonItem>
                    <IonList>
                        {
                            carrier.feedback.map((f, key) => (
                                <FeedbackItem key={key} feedback={f} />
                            ))
                        }
                    </IonList>
                </IonContent>
                <IonFooter>
                    <IonToolbar>
                        <IonButton
                            onClick={() => onDismiss()}
                        >
                            Go Back
                        </IonButton>
                        <IonButton
                            onClick={() => handleCarrierSelect()}
                        >
                            Select This Carrier
                        </IonButton>
                    </IonToolbar>
                </IonFooter>
            </>
        )
    }

export default CarrierItem
