import { Carrier } from "./carrier";
import { Feedback } from "./feedback"

export class Profile {
  email
  firstName
  lastName
  userName
  phoneNumber
  carrier
  reputation
  orders
  feedback
  
  constructor(
    email: string,
    firstName: string,
    lastName: string,
    userName: string,
    phoneNumber: string,
    carrier: Carrier = new Carrier(),
    reputation: number = 0,
    orders: string[] = [],
    feedback: Feedback[] = []
  ) {
    this.email = email
    this.firstName = firstName
    this.lastName = lastName
    this.userName = userName
    this.phoneNumber = phoneNumber
    this.carrier = carrier
    this.reputation = reputation
    this.orders = orders
    this.feedback = feedback
  }

  data() {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      userName: this.userName,
      email: this.email,
      phoneNumber: this.phoneNumber,
      reputation: this.reputation,
      orders: this.orders,
      carrier: this.carrier,
      feedback: this.feedback,
    } as Profile
  }
}