import { Geolocation } from "@ionic-native/geolocation";
import React, { useState, useEffect, useRef } from "react";
import "../style.css";
import { IonButton, IonCol, IonGrid, IonHeader, IonItem, IonLoading, IonRow, IonTitle, IonToolbar, useIonModal } from "@ionic/react";
import { toast } from "../../../../toast";
import AddressInput from "./AddressInput"

interface LocationError {
  showError: boolean;
  message?: string;
}

interface MapPosition {
  position: google.maps.LatLngLiteral;
  zoom: number;
}

const MapComponent: React.FC<{
  dismiss: () => void,
  onLocationSelected: (data: google.maps.LatLng, address: string) => void
}> = ({dismiss, onLocationSelected}) => {

  const [loading, setLoading] = useState(true);
  const initialMapOptions = {
    center: { lat: 58.6454381, lng: 25.4247266 },
    zoom: 7.88,
    disableDefaultUI: true,
    clickableIcons: false,
  };
  const [mapPosition, setMapPosition] = useState<MapPosition>({position: { lat: 58.6454381, lng: 25.4247266 }, zoom: 7.88});
  const [error, setError] = useState<LocationError>({ showError: false });
  const [map, setMap] = useState<google.maps.Map>();
  const [selectedPosition, setSelectedPosition] = useState<google.maps.LatLngLiteral>();
  const [address, setAddress] = useState("");
  const [marker, setMarker] = useState<google.maps.Marker>();
  

  const [chosenPlaceId, setChosenPlaceId] = useState("");
  const usePlacePositions = (placeId: string) => {
      const [results, setResults] = useState<google.maps.GeocoderGeometry[]>([]);

      useEffect(() => {
          if (placeId != "") {
              console.debug("Geocoding", placeId);
              const service = new google.maps.Geocoder();
              service.geocode(
                  {placeId},
                  (geocodeResults, status) => {
                    if (status != google.maps.GeocoderStatus.OK
                        || geocodeResults === null)
                    {
                      console.error("Geocode error:", status);
                      setResults([]);
                    } else {
                      console.debug("Geocode result:", status, geocodeResults);
                      setResults(geocodeResults.map(r => r.geometry));
                    }
                  });
          } else {
              setResults([]);
          }
      }, [placeId]);
      return results;
  };
  const chosenPlacePositions = usePlacePositions(chosenPlaceId);

  useEffect(() => {
    if (map !== null && chosenPlacePositions.length > 0) {
      const geometry = chosenPlacePositions[0];
      map?.fitBounds(geometry.viewport);
      moveMarker(geometry.location.toJSON());
    }
  }, [map, chosenPlacePositions])

  const [showAddressInput, dismissAddressInput] = useIonModal(AddressInput, {
    onPlaceChosen: (placeId: string) => {
      setChosenPlaceId(placeId);
      dismissAddressInput();
    },
    onDismiss: () => dismissAddressInput()
  })

  const mapContainer = useRef<HTMLDivElement>(null);

  const moveMarker = (newPosition: google.maps.LatLngLiteral) => {
    if (marker === undefined) {
      const newMarker = new google.maps.Marker({
        map: map,
        draggable: true,
        animation: google.maps.Animation.DROP,
        position: newPosition,
      });
      newMarker.addListener('dragend', (event: google.maps.MapMouseEvent) => {
        console.debug("Marker dragged to:", event.latLng?.toJSON());
        setSelectedPosition(event.latLng?.toJSON());
      });
      setMarker(newMarker);
      console.debug("New marker created at:", newMarker.getPosition()?.toJSON());
    } else {
      marker.setPosition(newPosition);
      marker.setAnimation(google.maps.Animation.DROP); // run animation again
      console.debug("Marker moved to:", marker.getPosition()?.toJSON());
    }
    setSelectedPosition(newPosition);
  };

  useEffect(() => {
    if (selectedPosition !== undefined) {
      const controller = new AbortController();
      const { signal } = controller;
      fetch("https://maps.googleapis.com/maps/api/geocode/json?latlng="
            + selectedPosition.lat.toString() + "," + selectedPosition.lng.toString()
            + "&key=" + process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            { signal })
            .then(response => response.json())
            .then(json => setAddress(json.results[0].formatted_address.replace(/^[A-Z0-9]+\+[A-Z0-9]+ /, '')))
            .catch((e) => {
              console.debug("Error fetching reverse geocode:", e);
              setAddress('');
            });

      return () => { controller.abort(); };
    }
  }, [selectedPosition]);

  useEffect(() => {
    if (map !== undefined) {
      const listener = map.addListener('click', (event: google.maps.MapMouseEvent) => {
        if (event.latLng !== null) {
          console.debug("Map clicked at:", event.latLng.toJSON());
          moveMarker(event.latLng.toJSON());
        }
      });
      return () => { google.maps.event.removeListener(listener); }
    }
  }, [map, marker]) // moveMarker needs last state of marker

  useEffect(() => {
      if (map !== undefined) {
        map.setCenter(mapPosition.position);
        map.setZoom(mapPosition.zoom);
      }
  }, [map, mapPosition]);

  useEffect(() => {
    if (map !== undefined) {
      setLoading(false);
    }
  }, [map])

  useEffect(() => {
    if (mapContainer.current && !map) {
        setMap(new google.maps.Map(mapContainer.current, initialMapOptions));
    }
  }, [mapContainer, map]);

  const getLocation = async () => {
    setLoading(true);
    await Geolocation.getCurrentPosition()
    .then(position => {
      const googlePos = { position: {lat: position.coords.latitude, lng: position.coords.longitude}, zoom: 18 }
      setMapPosition(googlePos);
      moveMarker(googlePos.position);
      setLoading(false);
      setError({ showError: false, message: undefined });
    })
    .catch(e => {
      const message: string = e.message.length > 0 ? e.message : "Cannot get user location. Check permissions";
      setError({ showError: true, message });
      setLoading(false);
      toast(message);
    });
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonItem className='ion-no-padding' lines='none'>
                            <IonButton
                                color='danger'
                                onClick={() => { dismiss(); }}
                            >Cancel</IonButton>
                            <IonTitle className='ion-text-center'>Select location</IonTitle>
                            <IonButton disabled={selectedPosition === undefined || address === ""} onClick={() => {
                                if (selectedPosition !== undefined) {
                                  onLocationSelected(new google.maps.LatLng(selectedPosition), address)
                                  dismiss()
                                }
                            }}>Finish</IonButton>
                        </IonItem>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonToolbar>
    </IonHeader>
      <div className="map-container" ref={mapContainer}>
        <IonLoading
          isOpen={loading}
          message={"Loading map..."}
          />
      </div>
      <p className='ion-text-center'>{address}</p>
      <IonButton className='ion-padding-horizontal' onClick={() => showAddressInput()} disabled={map ? false : true}>
        Go to address
      </IonButton>
      <IonButton className='ion-padding-horizontal ion-margin-bottom' onClick={getLocation} disabled={map ? false : true}>
        Go to your current location
      </IonButton>
    </>
  );
};

export default MapComponent;
