import { IonPage, IonContent, IonRow, IonGrid, IonFooter, IonToolbar } from '@ionic/react'
import { useSelector } from 'react-redux'
import ListOf from '../../../components/ListOf'
import { Profile } from '../../../data/profile'

const FeedbackView: React.FC = () => {

    const { profile }: { profile: Profile } = useSelector((state: any) => state.firebase)
    const reputation = profile.reputation

    return (
        <IonPage>
            <IonContent fullscreen={true}>
                <IonGrid className='page-content'>
                    <IonRow>
                        <ListOf role='sender' type='feedbacks' />
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter>
                <IonToolbar>
                    <h4 className='ion-text-center'>Reputation: {reputation} / 10</h4>
                </IonToolbar>
            </IonFooter>
        </IonPage>
    )

}

export default FeedbackView