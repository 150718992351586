import { 
    IonPage, IonContent, IonButton, IonIcon,
    useIonLoading
} from "@ionic/react"
import { useHistory } from "react-router-dom";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { collection, addDoc, Firestore, arrayUnion, GeoPoint, Timestamp } from "firebase/firestore";
import { getAuth, signInWithPopup, OAuthProvider, getAdditionalUserInfo } from "firebase/auth";
import { useEffect } from "react";
import '../css/Welcome.css'
import { Profile } from '../../data/profile'
import { Carrier } from "../../data/carrier"
import { Route } from "../../data/route";


const AddRoutes: React.FC = () => {

    return (
        <IonPage>
            <IonContent className='ion-padding'>
                <h1 className='ion-text-center ion-padding'>Route Creator!</h1>
                {/* <IonButton
                    data-color="black" data-border="true" data-type="sign-in"
                    id="appleid-signin"
                    expand="block"
                    className="ion-padding signin-button"
                    onClick={createRoutes}
                    color="dark"
                >
                    Create routes
                    </IonButton> */}

            </IonContent>
        </IonPage>
    )
}

export default AddRoutes