import { IonButton, IonItem, IonLabel, IonText, useIonModal } from "@ionic/react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import { Order } from '../data/order'
import Collapsible from 'react-collapsible'

import '../pages/css/OrderItem.css'
import OrderChat from "./OrderChat";
import { GeoPoint, Timestamp } from "firebase/firestore";
import NewFeedbackView from "../pages/dashboard/sender/NewFeedbackView";
import { toast } from "../toast";


const OrderItem: React.FC<{
    order: {
        id: string,
        data: Order
    },
}> = ({ order }) => {

    const auth = useSelector((state: any) => state.firebase.auth)
    const messages = useSelector((state: any) => state.firestore.data[`messages_${order.id}`])
    const firestore = useFirestore()

    const rejectOrder = () => {
        firestore.collection('orders').doc(order.id).update({
            status: 'canceled'
        })
    }
    const acceptOrder = () => {
        firestore.collection('orders').doc(order.id).update({
            status: 'active'
        })
    }

    const [openChat, dismissChat] = useIonModal(OrderChat, {
        dismiss: () => dismissChat(),
        messages: messages,
        order: order,
        current_id: auth.uid,
        firestore
    })

    const cancel = () => {
        firestore.collection('orders').doc(order.id).update({
            status: 'canceled'
        })
    }

    const geoPointToString = (p: GeoPoint) => {
        return `(${p.latitude}, ${p.longitude})`;
    }

    const [giveFeedback, dismissFeedback] = useIonModal(NewFeedbackView, {
        onDismiss: () => dismissFeedback(),
        user: auth,
        firestore,
        order
    })

    const nextStep = () => {
        const step = order.data.process
        let next

        if (step === 'pickup') next = 'dropoff'
        else if (step === 'dropoff') next = 'done'

        if (next === 'done') firestore.collection('orders').doc(order.id).update({
            process: next,
            status: 'completed',
            completed: Timestamp.now()
        })
        else firestore.collection('orders').doc(order.id).update({
            process: next
        })
            .catch(err => toast(err, 3000))
    }

    function renderFeedbackButton() {
        // TODO: check whether the target was already given feedback for this order
        return <IonButton onClick={() => giveFeedback()} color='primary' slot='end'>Feedback</IonButton>
    }

    return (
        <Collapsible className='itemTitle' openedClassName='itemTitle' trigger={"Order: " + order.id}>
            <div className='itemContent'>
                <IonItem color='light'>
                    <IonLabel position='stacked'>Pick-up address: </IonLabel>
                    <IonText className='address item'>{order.data.pickupAddress} {geoPointToString(order.data.pickup)}</IonText>
                </IonItem>
                <IonItem color='light'>
                    <IonLabel position='stacked'>Destination: </IonLabel>
                    <IonText className='address item'>{order.data.dropoffAddress} {geoPointToString(order.data.dropoff)}</IonText>
                </IonItem>
                <IonItem color='light'>
                    <IonLabel position='stacked'>Recipient info: </IonLabel>
                    <IonText className='address item'>{order.data.recipient}</IonText>
                </IonItem>
                <IonItem color='light'>
                    <IonLabel position='stacked'>Package info: </IonLabel>
                    <IonText className='address item'>{order.data.fragile ? 'fragile' : 'not fragile'}</IonText>
                </IonItem>
                {
                    (() => {
                        if (auth.uid === order.data.creatorId) { // sender

                            if (order.data.status === 'pending') return (
                                <IonItem color='light' className='ion-align-items-stretch'>
                                    <IonButton onClick={() => cancel()} color='warning' slot='end'>Cancel</IonButton>
                                </IonItem>
                            )
                            else if (order.data.status === 'active' && order.data.process === 'pickup') return (
                                <IonItem color='light' className='ion-align-items-stretch'>
                                    <IonButton onClick={() => openChat()} color='warning' slot='start'>chat</IonButton>
                                    <IonButton onClick={() => cancel()} color='danger' slot='end'>cancel</IonButton>
                                </IonItem>
                            )
                            else if (order.data.status === 'active' && order.data.process === 'dropoff') return (
                                <IonItem color='light' className='ion-align-items-stretch'>
                                    <IonButton onClick={() => openChat()} color='warning' slot='start'>chat</IonButton>
                                    {renderFeedbackButton()}
                                </IonItem>
                            )
                            else return (
                                <IonItem color='light' className='ion-align-items-stretch'>
                                    <IonButton onClick={() => openChat()} color='warning' slot='start'>chat</IonButton>
                                    {renderFeedbackButton()}
                                </IonItem>
                            )

                        } else { // else carrier

                            if (order.data.status === 'pending') return (
                                <IonItem color='light' className='ion-align-items-stretch'>
                                    <IonButton onClick={() => acceptOrder()} color='primary' slot='start'>Accept</IonButton>
                                    <IonButton onClick={() => rejectOrder()} color='warning' slot='end'>Reject</IonButton>
                                </IonItem>
                            )
                            else if (order.data.status === 'active' && order.data.process === 'pickup') return (
                                <IonItem color='light' className='ion-align-items-stretch'>
                                    <IonButton onClick={() => openChat()} color='warning' slot='start'>chat</IonButton>
                                    <IonButton onClick={() => nextStep()} color='primary'>package recieved</IonButton>
                                    <IonButton onClick={() => cancel()} color='danger' slot='end'>cancel</IonButton>
                                </IonItem>
                            )
                            else if (order.data.status === 'active' && order.data.process === 'dropoff') return (
                                <IonItem color='light' className='ion-align-items-stretch'>
                                    <IonButton onClick={() => openChat()} color='warning' slot='start'>chat</IonButton>
                                    <IonButton onClick={() => nextStep()} color='primary'>package delivered</IonButton>
                                </IonItem>
                            )
                            else return (
                                <IonItem color='light' className='ion-align-items-stretch'>
                                    <IonButton onClick={() => openChat()} color='warning' slot='start'>chat</IonButton>
                                    {renderFeedbackButton()}
                                </IonItem>
                            )

                        }
                    })()
                }
            </div>
        </Collapsible>
    )


}


export default OrderItem