import { IonPage, IonContent, IonGrid, IonRow} from '@ionic/react'
import ListOf from '../../../components/ListOf'

import './style.css'

const HistoryView: React.FC = () => {


    return (
        <IonPage>
            <IonContent fullscreen={false}>
                <IonGrid className='page-content'>
                    <IonRow>
                        
                            <ListOf role='sender' state='completed' type='orders'/>
                        
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    )
}


export default HistoryView