import { Route } from "./route"

export class Carrier {
  routes
  reputation
  price

  constructor(
    routes: Route[] = [],
    reputation: number = 0,
    price: number = 0
  ) {
    this.routes = routes
    this.reputation = reputation
    this.price = price
  }

  data() {
    return {
      routes: this.routes,
      reputation: this.reputation,
      price: this.price,
    } as Carrier
  }
}