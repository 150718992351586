import { IonList } from "@ionic/react"
import { colorWandOutline } from "ionicons/icons"
import { useSelector } from "react-redux"
import { isEmpty, isLoaded } from "react-redux-firebase"
import { Feedback } from '../data/feedback'
import { Order } from '../data/order'
import { Profile } from '../data/profile'
import FeedbackItem from "./FeedbackItem"
import OrderItem from "./OrderItem"

const ListOf: React.FC<{
  state?: 'pending' | 'active' | 'completed',
  role: 'carrier' | 'sender',
  type: 'orders' | 'feedbacks'
}> = ({ state = '', role, type }) => {

  const { auth, profile }: { auth: any, profile: Profile } = useSelector((state: any) => state.firebase)
  const { orders }: { orders: Order[] } = useSelector((state: any) => state.firestore.data)
  const { feedbacks }: { feedbacks: Feedback[] } = useSelector((state: any) => state.firestore.data)

  const collection = type === 'feedbacks' ? feedbacks : orders


  const returnMessage = () => {
    let msg = 'No '

    if (type !== 'feedbacks') {
      if (role === 'carrier') msg += (state === 'active' ? 'accepted ' : (state === 'pending' ? 'requested ' : 'completed ')) + 'deliveries'
      else msg += state + ' orders'
    }
    else msg += 'feedback'

    return msg
  }

  const returnValue = (
    payload?:
      string |
      { id: string, data: Order }[] |
      Feedback[]
  ) => {
    let value = <IonList className='ion-text-center'><h2>{returnMessage()}</h2></IonList>

    if (Array.isArray(payload)) {
      value = (
        <IonList>
          {
            payload.map((el, index) => {
              if (type === 'feedbacks') {
                return <FeedbackItem key={index} feedback={el as Feedback} />
              } else {
                let myFeedback = null
                if (feedbacks !== undefined && !isEmpty(feedbacks))
                  myFeedback = Object.values(feedbacks).find(f =>
                    f.orderId === (el as { id: string, data: Order }).id && f.author.id === auth.uid)

                return <OrderItem
                  key={index}
                  order={el as { id: string, data: Order }}
                />
              }
            })
          }
        </IonList>
      )
    }
    else if (payload) {
      value = <IonList><h2>{payload}</h2></IonList>
    }

    return value
  }


  // if not logged in
  if (!auth.uid) return returnValue('Not logged in!')

  // if required role is carrier and user is not a carrier then do nothing
  if (role === 'carrier' && !profile.carrier) return returnValue()

  // data still loading
  if (!isLoaded(collection)) returnValue('Loading..')

  // data loaded and contains data
  else if (!isEmpty(collection)) {
    if (type !== 'feedbacks') {
      return returnValue((() => {

        // create a list from query object
        const list = Object.entries(collection).map(el => {
          return {
            id: el[0] as string,
            data: el[1] as Order
          }
        })

        // filter wrong status from orders
        let filteredList = list.filter(el => (el.data as Order).status === state)

        if (role === 'carrier') filteredList = filteredList.filter(el => (el.data as Order).carrierId === auth.uid)
        else if (role === 'sender') filteredList = filteredList.filter(el => (el.data as Order).creatorId === auth.uid || (el.data as Order).recipient === auth.uid)

        if (filteredList.length === 0) return

        return filteredList
      })())
    }

    return returnValue((() => {
      const list = Object.entries(collection).map(el => {
        return el[1] as Feedback
      })

      let filteredList = list.slice()

      if (role === 'carrier') filteredList = filteredList.filter(el =>
        (el as Feedback).role === 'carrier')

      else if (role === 'sender') filteredList = filteredList.filter(el =>
        (el as Feedback).role === 'sender')

      if (filteredList.length === 0) return

      return filteredList
    })())
  }

  return returnValue()
}


export default ListOf
