import React, { useState, useRef, useEffect } from 'react'
import { IonInput, IonList, IonItem, IonButton } from '@ionic/react'

const AddressInput: React.FC<{
    onPlaceChosen: (placeId: string) => void,
    onDismiss: () => void
}> = ({onPlaceChosen, onDismiss}) => {
    // See https://sebastiandedeyne.com/writing-a-custom-react-hook-google-places-autocomplete/
    const [input, setInput] = useState("");
    const useAutocomplete = (input: string) => {
        const [results, setResults] = useState<google.maps.places.AutocompletePrediction[]>([]);
        const service = useRef(new google.maps.places.AutocompleteService);

        useEffect(() => {
            if (input != "") {
                console.debug("Getting place predictions:", input);
                service.current.getPlacePredictions(
                    {input},
                    predictions => {
                        console.debug("Got place predictions:", predictions);
                        if (predictions !== null) {
                            setResults(predictions);
                        } else {
                            setResults([]);
                        }
                    });
            } else {
                setResults([]);
            }
        }, [input]);
        return results;
    };
    const results = useAutocomplete(input);

    return (
        <>
            <IonInput
                placeholder="Type address to search..."
                autofocus={true}
                value={input}
                onIonChange={e => setInput(e.detail.value!)}
            />
            <IonList>
                {results.map((prediction, index) => (
                    <IonItem button
                     onClick={() => onPlaceChosen(prediction.place_id)}
                     key={index}
                    >{prediction.description}</IonItem>
                ))}
            </IonList>
            <IonButton onClick={onDismiss}>Cancel</IonButton>
        </>
    )
}

export default AddressInput
