import { toast } from "../../../../toast"

export const addressValidator = (addr: string, msg: string) => {
    // (?=.*[A-Za-z]) POSITIVE LOOKAHEAD for at least one alpha char
    // (?=.*\d) POSITIVE LOOKAHEAD for at least one digit char
    // [A-Za-z0-9\-#\\.\\/\s]* look for any valid char to the end of line
    // const validator = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z0-9\-#\\.\\/\s,]*$/

    // new
    const validator = /^(([A-Za-züõöäÜÕÖÄ]+)\s{1,}([A-Za-z0-9\\-]+))(,\s*([A-Za-zÜÕÖÄäüõö\\-]+\s*|[0-9]+\s*)+)*$/
    
    if (validator.test(addr)) return true

    toast(msg)
    return false
}

export const recipientNumberValidator = (number: string, msg: string) => {
    const validator = /^(\+?[0-9]+)?([-\s])?([0-9]{8})$/

    if (validator.test(number)) return true

    toast(msg)
    return false
}

export const sizeValidator = (size: number, msg: string) => {
    if (size > 0 && size < 10000) return true
    toast(msg)
    return false
}
