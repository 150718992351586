import { Timestamp } from "firebase/firestore"

export class Feedback {
  role
  rating
  comment
  author
  targetId
  orderId
  date

  constructor(
    role: "carrier" | "sender",
    rating: number,
    comment: string,
    author: {
      displayName: string,
      id: string
    },
    targetId: string,
    orderId: string,
    date: Timestamp = Timestamp.now(),
  ) {
    this.role = role
    this.rating = rating
    this.comment = comment
    this.author = author
    this.targetId = targetId
    this.orderId = orderId
    this.date = date
  }

  data() {
    return {
      role: this.role,
      rating: this.rating,
      comment: this.comment,
      author: this.author,
      targetId: this.targetId,
      orderId: this.orderId,
      date: this.date,
    } as Feedback
  }
}