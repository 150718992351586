import { IonButton, IonCol, IonContent, IonGrid, IonItem, IonPage, IonRow, IonText, IonTitle } from "@ionic/react"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { isLoaded, useFirebase } from "react-redux-firebase"
import { useHistory } from "react-router"
import { toast } from "../toast"



const EmailVerification: React.FC = () => {

    const {auth} = useSelector((state: any) => state.firebase)
    const firebase = useFirebase()
    const history = useHistory()

    const refresh = async () => {
        firebase.reloadAuth()

        if (isLoaded(auth)) {
            if (auth.emailVerified) history.push('/sender/orders')
            else if (!auth.emailVerified) toast('Email not yet verified')
        }
    }

    // useEffect(() => {}, [auth])

    return (
        <IonPage>
            <IonContent fullscreen>
                {
                    isLoaded(auth) && !auth.emailVerified ? 
                    <div>
                        <IonGrid>
                            <IonRow>
                                <IonCol className='ion-text-center'>

                                    <IonItem lines='none' className='ion-text-center ion-padding-top'>
                                        <IonTitle className='ion-padding ion-text-center'>Verifiy email address and then refresh</IonTitle>
                                    </IonItem>

                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className='ion-text-center'>

                                    <IonButton color='success' onClick={() => refresh()}>Refresh</IonButton>

                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className='ion-text-center'>

                                    <IonButton size='small' onClick={() => {
                                        firebase.logout()
                                        history.goBack()
                                    }}>Back</IonButton>

                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>
                    :
                    <div>
                        <IonRow>
                            <IonCol>
                                <IonText>Loading...</IonText>
                            </IonCol>
                        </IonRow>
                    </div>
                }
            </IonContent>
        </IonPage>
    )
}

export default EmailVerification