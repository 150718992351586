import { IonContent, IonHeader, IonPage, IonRouterOutlet, IonSegment, IonSegmentButton, IonToolbar } from "@ionic/react"
import React, { useState } from "react"
import { Redirect, Route, useHistory } from "react-router"
import FeedbackView from "./FeedbackView"
import HistoryView from "./HistoryView"
import OrdersView from "./OrdersView"

const SenderPage: React.FC = () => {
    const [page, setPage] = useState('orders')
    const history = useHistory()
    
    return (
    <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonSegment value={page} onIonChange={(e) => {
                    setPage(e.detail.value!)
                    history.push(`/sender/${e.detail.value}`)
                }}>
                    <IonSegmentButton value='orders'>Orders</IonSegmentButton>
                    <IonSegmentButton value='history'>History</IonSegmentButton>
                    <IonSegmentButton value='feedback'>Feedback</IonSegmentButton>
                </IonSegment>
            </IonToolbar>
        </IonHeader>
        <IonContent fullscreen={false}>
                <IonRouterOutlet basePath='/sender'>
                    <Redirect from='/sender' exact to='/sender/orders'/>
                    <Route path='/sender/orders' component={OrdersView}/>
                    <Route path='/sender/history' component={HistoryView}/>
                    <Route path='/sender/feedback' component={FeedbackView}/>
                </IonRouterOutlet>
        </IonContent>
    </IonPage>
)}

export default SenderPage