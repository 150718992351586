
import React, { useState } from 'react';
import { IonPage, IonContent, IonSegment, IonSegmentButton, IonLabel} from '@ionic/react'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore from 'swiper'
import { IonicSwiper } from '@ionic/react';

import 'swiper/swiper-bundle.min.css';
import '@ionic/react/css/ionic-swiper.css';
import ListOf from '../../../components/ListOf';


SwiperCore.use([IonicSwiper])

const DeliveriesView: React.FC = () => {

    const [swiper, setSwiper] = useState<SwiperCore>()
    const [segment, setSegment] = useState("0")

    const handleSegment = (number: number) => {
      if (swiper) swiper.slideTo(number)
    }

    return (
        <IonPage>
            <IonContent fullscreen={true}>

                <IonSegment className='ion-padding' value={segment} color='secondary' onIonChange={(e) => handleSegment(Number(e.detail.value))}>
                    <IonSegmentButton value="0">
                        <IonLabel>Accepted</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="1">
                        <IonLabel>Requested</IonLabel>
                    </IonSegmentButton>
                </IonSegment>

                <IonContent fullscreen={false}>
                    <Swiper spaceBetween={50} onSwiper={(swiper) => {
                        setSwiper(swiper)
                      }}
                      onSlideChange={(e) => setSegment(e.activeIndex.toString())}
                    >
                      <SwiperSlide>
                        <ListOf role='carrier' state='active' type='orders'/>
                      </SwiperSlide>
                      <SwiperSlide>
                        <ListOf role='carrier' state='pending' type='orders'/>
                      </SwiperSlide>
                    </Swiper>
                </IonContent>

            </IonContent>
        </IonPage>
    )

}

export default DeliveriesView