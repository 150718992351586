
import {
    IonPage,
    IonContent,
    IonButton,
    IonTitle,
    useIonLoading
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import { toast } from "../../toast";
// Assuming you're using Firebase for authentication. If not, replace with the appropriate library.
import { useFirebase } from "react-redux-firebase";
import { getAuth, signInWithPopup, OAuthProvider, getAdditionalUserInfo } from "firebase/auth";



const AppleSignIn: React.FC = () => {
    const firebase = useFirebase();
    const history = useHistory();
    const [loading, dismissLoading] = useIonLoading();



    const provider = new OAuthProvider('apple.com');

    provider.addScope('email');
    provider.addScope('name');

    function signInWithApple() {
        loading('Signing In with Apple', 5000);

        const auth = getAuth();
        signInWithPopup(auth, provider)
        .then((result) => {
            // The signed-in user info.
            const user = result.user;

            // Apple credential
            const credential = OAuthProvider.credentialFromResult(result);
            const accessToken = credential?.accessToken;
            const idToken = credential?.idToken;

            console.log('logged in!!');

            // IdP data available using getAdditionalUserInfo(result)
            // ...

            console.log(getAdditionalUserInfo(result));

            history.push('/sender/orders');

        })
        .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The credential that was used.
            const credential = OAuthProvider.credentialFromError(error);
            console.log(errorMessage);
            // ...
        });
    }

    return (
        <IonPage>
            <IonContent className='ion-padding'>
                <IonTitle className='ion-text-center ion-text-uppercase'>Sign In With Apple</IonTitle>
                <div>
                    <IonButton
                        expand="block"
                        onClick={signInWithApple}
                    >Sign In with Apple</IonButton>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default AppleSignIn;
