import { useEffect, useState } from "react";


interface RouteSetupMarkerProps extends google.maps.MarkerOptions {
    onDragStart?: (e: google.maps.MapMouseEvent) => void,
    onDragEnd?: (e: google.maps.MapMouseEvent) => void,
    onClick?: (e: google.maps.MapMouseEvent) => void,
    disabled?: boolean
}

const RouteMarker: React.FC<RouteSetupMarkerProps> = (options: RouteSetupMarkerProps) => {
    const [marker, setMarker] = useState<google.maps.Marker>();

    useEffect(() => {
        if (!marker) {
            setMarker(new google.maps.Marker());
        }
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        }
    }, [marker]);

    useEffect(() => {
        if (marker && !options.disabled) {
            marker.setOptions(options);
            ['click', 'dragend', 'dragstart'].forEach(eventName => google.maps.event.clearListeners(marker, eventName));
            if (options.onClick) {
                marker.addListener('click', options.onClick);
            }
            if (options.onDragEnd) {
                marker.addListener('dragend', options.onDragEnd);
            }
            if (options.onDragStart) {
                marker.addListener('dragstart', options.onDragStart);
            }
        }
    }, [marker, options]);

    return null;
}

export default RouteMarker