
/**
 * Toast notification. Displays over the screen not only in one component
 * @param {string} message message to display
 * @param {number} duration display time in ms. 2000 by default
 * @returns 
 */
export function toast(message: string, duration = 2000) {
    const toast = document.createElement('ion-toast')
    toast.message = message
    toast.duration = duration
    toast.position = 'top' // top / bottom / middle

    document.body.appendChild(toast)
    return toast.present()
}