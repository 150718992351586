import React from "react";
import { IonItem, IonLabel } from "@ionic/react";
import { PresentCarrier } from "../../../../data/presentCarrier";

const CarrierRecommendation: React.FC<{
  carrier: PresentCarrier,
  showCarrier: () => void
}> = ({
  carrier, showCarrier
}) => {
    return (
      <IonItem className='carrierItem' onClick={() => showCarrier()}>
        <IonLabel className='carrierItemDisplayName'>
          {carrier.displayName}
        </IonLabel>
        <IonLabel className='carrierItemReputation'>
          {
            carrier.reputation !== 0 ? 
          
            "★".repeat(Math.round(carrier.reputation * 5.0)) +
              "☆".repeat(5 - Math.round(carrier.reputation * 5.0))
              :
              "Not rated"
            
          }
        </IonLabel>
        <IonLabel className='carrierItemPrice'>{carrier.price} €</IonLabel>
      </IonItem>
    )
};

export default CarrierRecommendation;
