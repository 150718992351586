import { IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from "@ionic/react"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { isEmpty, isLoaded, OrderByOptions, ReduxFirestoreQuerySetting, useFirestoreConnect } from "react-redux-firebase"
import EmailVerification from "../../components/EmailVerification"


const Navigation: React.FC<any> = ({ children }) => {

  // auth from firebase authentication data
  // profile from firestore users under uid
  const { auth, profile } = useSelector((state: any) => state.firebase)

  // listener for order documents listed in profile under order array
  useFirestoreConnect(() => {
    if (
      !isLoaded(auth) ||
      isEmpty(auth) ||
      !isLoaded(profile) ||
      isEmpty(profile)
    ) return []

    return profile.orders.map((ref: string) => {
      return {
        collection: 'orders',
        doc: ref
      }
    })
  })

  useFirestoreConnect(() => {
    if (
      !isLoaded(auth) ||
      isEmpty(auth) ||
      !isLoaded(profile) ||
      isEmpty(profile)
    ) return []

    return profile.orders.map((ref: string) => {
      return {
        collection: 'orders',
        doc: ref,
        subcollections: [{ collection: 'messages' }],
        storeAs: `messages_${ref}`,
        orderBy: ['createdAt', 'asc'] as OrderByOptions,
        // limit: 10,
      } as ReduxFirestoreQuerySetting
    })
  })

  // // listener for feedback documents listed in profile under feedback collection
  useFirestoreConnect(() => {
    if (
      !isLoaded(auth) ||
      isEmpty(auth) ||
      !isLoaded(profile) ||
      isEmpty(profile)
    ) return []

    return profile.feedback.map((ref: string) => {
      return {
        collection: 'feedbacks',
        doc: ref
      }
    })
  })

  // rerender app if these states change in store
  const { orders } = useSelector((state: any) => state.firestore.data)
  useEffect(() => { }, [orders, auth])

  const feedbacks = useSelector((state: any) => state.firestore.data)
  useEffect(() => { }, [feedbacks, auth])



  return (
    <>
      {
        isLoaded(auth) && auth.emailVerified ?
          <IonTabs>
            <IonRouterOutlet>

              {children}

            </IonRouterOutlet>

            <IonTabBar slot='bottom'>
              <IonTabButton tab='sender' href='/sender'>
                <IonLabel>Sender</IonLabel>
              </IonTabButton>

              <IonTabButton tab='carrier' href='/carrier'>
                <IonLabel>Carrier</IonLabel>
              </IonTabButton>

              <IonTabButton tab='profile' href='/profile'>
                <IonLabel>Profile</IonLabel>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
          :
          <EmailVerification />
      }
    </>
  )
}

export default Navigation
