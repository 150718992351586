import {
    IonPage,
    IonInput,
    IonContent,
    IonButton,
    IonItem,
    IonLabel,
    IonTitle,
    useIonLoading,
    IonRouterLink,
} from "@ionic/react"
import { useHistory } from "react-router-dom"
import { useEffect, useRef } from "react"
import { toast } from "../../toast"
import { useFirebase } from "react-redux-firebase"
import { useSelector } from "react-redux"
import { sendEmailVerification, getAuth } from "firebase/auth"

const Login: React.FC = () => {
    const { auth } = useSelector((state: any) => state.firebase)
    const firebase = useFirebase()
    const history = useHistory()

    const [loading, dismissLoading] = useIonLoading()
    
    const emailRef = useRef('')
    const passwordRef = useRef('')
    
    useEffect(() => { }, [auth])


    
    function login() {
        loading('Signing In', 5000)

        firebase.login({email: emailRef.current, password: passwordRef.current})
            .then(dat => {

                // @ts-ignore
                const verified = dat.user.user.multiFactor.user.emailVerified
                const { currentUser } = getAuth()
                if (!verified) sendEmailVerification(currentUser!)
            })
            .then(() => {
                dismissLoading()
                history.push('/sender/orders')
            })
            .catch((err) => {
                dismissLoading()
                toast(`${err.message}`, 5000)
            })
    }

    return (
        <IonPage>
            <IonContent className='ion-padding'>
                <IonTitle className='ion-text-center ion-text-uppercase'>Sign In</IonTitle>
                <div>
                    <IonItem>
                        <IonLabel position="floating">Email</IonLabel>
                        <IonInput
                            type='email'
                            value={emailRef.current}
                            onIonChange={e => emailRef.current = e.detail.value!}
                            title='Email input'
                        />
                    </IonItem>
                    <IonItem className="ion-margin-bottom">
                        <IonLabel position="floating">Password</IonLabel>
                        <IonInput
                            type='password'
                            value={passwordRef.current}
                            onIonChange={e => passwordRef.current = e.detail.value!}
                            title='Password input'
                        />
                    </IonItem>
                    <IonButton
                        expand="block"
                        onClick={login}
                    >Sign In</IonButton>
                </div>
                <p className='ion-text-center'>
                    Don't have an account? <IonRouterLink onClick={() => history.push('/register')}>Sign Up</IonRouterLink>
                </p>
            </IonContent>
        </IonPage>
    )
}

export default Login
