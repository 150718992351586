import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import React from 'react';

import state from './store'
const {firebase, store, rrfProps} = state


// document.location.hostname === 'localhost'

if (false){
    firebase.auth().useEmulator('http://localhost:9099')
    firebase.functions().useEmulator('localhost', 5001)
    firebase.firestore().useEmulator('localhost', 8040)
    // firebase.database().useEmulator('localhost', 9000)
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ReactReduxFirebaseProvider {...rrfProps}>
                    <App />
            </ReactReduxFirebaseProvider>
        </Provider>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
