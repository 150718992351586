import { 
    IonPage, IonContent, IonButton, IonIcon,
    useIonLoading
} from "@ionic/react"
import { useHistory } from "react-router-dom";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { collection, addDoc, Firestore } from "firebase/firestore";
import { getAuth, signInWithPopup, OAuthProvider, getAdditionalUserInfo } from "firebase/auth";
import { useEffect } from "react";
import '../css/Welcome.css'
import { Profile } from '../../data/profile'
import { Carrier } from "../../data/carrier"


const Welcome: React.FC = () => {
    const firebase = useFirebase();
    const fireStore = useFirestore();
    const history = useHistory();
    const [loading, dismissLoading] = useIonLoading();



    const provider = new OAuthProvider('apple.com');

    provider.addScope('email');
    provider.addScope('name');

    useEffect(() => {
        const script = document.createElement('script');
    
        script.src = "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
        script.async = true;
    
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        }
      }, []);

    function signInWithApple() {
        loading('Signing In with Apple', 5000);

        const auth = getAuth();
        signInWithPopup(auth, provider)
        .then((result) => {
            // The signed-in user info.
            const user = result.user;



            // Apple credential
            const credential = OAuthProvider.credentialFromResult(result);
            const accessToken = credential?.accessToken;
            const idToken = credential?.idToken;

            console.log('logged in!!');

            // IdP data available using getAdditionalUserInfo(result)
            // ...

            console.log(getAdditionalUserInfo(result));

            let addInfo = getAdditionalUserInfo(result);

            if (addInfo?.isNewUser) {


                // console.log("uid", firestore.collection('users').doc(auth.uid))
                firebase.firestore().collection("users")
                    .doc(user.uid).set({
                        'email': user.email,
                        'userName': user.email,
                        'carrier': {
                            'price': 0,
                            'reputation': 0,
                            'routes': []
                        },
                        'feedback': [],
                        'firstName': '',
                        'lastName': '',
                        'orders': [],
                        'phoneNumber': '',
                        'reputation': 0
                    })
                    .then(result => {

                        console.log('User added!');
                    
                });
            }



            history.push('/sender/orders');

        })
        .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The credential that was used.
            const credential = OAuthProvider.credentialFromError(error);
            console.log(errorMessage);
            // ...
        });
    }

    
    return (
        <IonPage>
            <IonContent className='ion-padding'>
                <h1 className='ion-text-center ion-padding'>Welcome!</h1>
                
                <IonButton expand='block' className='ion-padding' routerLink='/login'>Sign In</IonButton>
                <IonButton
                    data-color="black" data-border="true" data-type="sign-in"
                    id="appleid-signin"
                    expand="block"
                    className="ion-padding signin-button"
                    onClick={signInWithApple}
                    color="dark"
                >
                    <IonIcon src="/assets/icon/logo-apple.svg" slot="start"></IonIcon>Sign In with Apple
                    </IonButton>
                <IonButton expand='block' fill='outline' className='ion-padding' routerLink='/register'>Sign Up</IonButton>

            </IonContent>
        </IonPage>
    )
}

export default Welcome