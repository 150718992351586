
import React, { useState } from 'react';
import { IonPage, IonContent, IonButton, IonSegment, IonSegmentButton, IonLabel, useIonModal} from '@ionic/react'
import { useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore from 'swiper'
import { IonicSwiper } from '@ionic/react';

import 'swiper/swiper-bundle.min.css';
import '@ionic/react/css/ionic-swiper.css';
import NewOrderView from './NewOrderView';
import ListOf from '../../../components/ListOf';


SwiperCore.use([IonicSwiper])

const OrdersView: React.FC = () => {
  
    const { auth } = useSelector((state: any) => state.firebase )
    const firestore = useFirestore()


    const [swiper, setSwiper] = useState<SwiperCore>()
    const [segment, setSegment] = useState("0")

    const handleSegment = (number: number) => {
      if (swiper) swiper.slideTo(number)
    }
    const [present, dismiss] = useIonModal(NewOrderView, {
      onDismiss: () => dismiss(),
      user: auth,
      firestore
    })

    return (
        <IonPage>
            <IonContent fullscreen={true}>
                <IonButton onClick={() => {
                  present()
                }} expand='full' color='tertiary' shape='round' className='ion-margin'>Create New Order</IonButton>

                <IonSegment className='ion-padding' value={segment} color='secondary' onIonChange={(e) => handleSegment(Number(e.detail.value))}>
                    <IonSegmentButton value="0">
                        <IonLabel>Active</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="1">
                        <IonLabel>Pending</IonLabel>
                    </IonSegmentButton>
                </IonSegment>

                <IonContent fullscreen={false}>
                    
                    <Swiper 
                      spaceBetween={50} onSwiper={(swiper) => {
                        setSwiper(swiper)
                      }}
                      onSlideChange={(e) => setSegment(e.activeIndex.toString())}
                    >
                      <SwiperSlide>
                        <ListOf role='sender' state='active' type='orders'/>
                      </SwiperSlide>
                      <SwiperSlide>
                        <ListOf role='sender' state='pending' type='orders'/>
                      </SwiperSlide>
                    </Swiper>

                </IonContent>

            </IonContent>
        </IonPage>
    )

}

export default OrdersView