import { GeoPoint, Timestamp } from "firebase/firestore";

export class Route {
  from: GeoPoint;
  to: GeoPoint;
  wayPoints: GeoPoint[];
  startDateTime: Timestamp;
  endDateTime: Timestamp;
  detourAmount: number;
  maxDeliveries: number;
  maxPackageWidth: number;
  maxPackageHeight: number;
  maxPackageDepth: number;
  fromAddress: string;
  toAddress: string;
  geohashRoute: string[];

  constructor(
    from: GeoPoint,
    to: GeoPoint,
    wayPoints: GeoPoint[],
    startDateTime: Timestamp,
    endDateTime: Timestamp,
    detourAmount: number,
    maxDeliveries: number,
    maxPackageWidth: number,
    maxPackageHeight: number,
    maxPackageDepth: number,
    fromAddress: string,
    toAddress: string,
    geohashRoute: string[]
  ) {
    this.from = from;
    this.to = to;
    this.wayPoints = wayPoints;
    this.startDateTime = startDateTime;
    this.endDateTime = endDateTime;
    this.detourAmount = detourAmount;
    this.maxDeliveries = maxDeliveries;
    this.maxPackageWidth = maxPackageWidth;
    this.maxPackageHeight = maxPackageHeight;
    this.maxPackageDepth = maxPackageDepth;
    this.fromAddress = fromAddress;
    this.toAddress = toAddress;
    this.geohashRoute = geohashRoute;
  }

  data() {
    return {
      from: this.from,
      to: this.to,
      wayPoints: this.wayPoints,
      startDateTime: this.startDateTime,
      endDateTime: this.endDateTime,
      detourAmount: this.detourAmount,
      maxDeliveries: this.maxDeliveries,
      maxPackageWidth: this.maxPackageWidth,
      maxPackageHeight: this.maxPackageHeight,
      maxPackageDepth: this.maxPackageDepth,
      fromAddress: this.fromAddress,
      toAddress: this.toAddress,
      geohashRoute: this.geohashRoute,
    } as Route;
  }
}