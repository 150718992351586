import React, { useEffect, useState } from "react";
import { useRef } from "react";



interface RouteMapProps {
    disabled?: boolean,
    bounds: google.maps.LatLngBounds
}

const initialMapOptions = {
    center: { lat: 58.6454381, lng: 25.4247266 },
    zoom: 7.88,
    disableDefaultUI: true,
    clickableIcons: false,
};



const RouteMap: React.FC<RouteMapProps> = ({
    disabled,
    bounds,
    children
}) => {

    const mapContainer = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();

    useEffect(() => {
        if (mapContainer.current && !map) {
            setMap(new google.maps.Map(mapContainer.current, {
                ...initialMapOptions,
                zoomControl: !disabled,
                gestureHandling: disabled ? 'none' : '',
                center: bounds.getCenter(),
                zoom: 7.2
            }));
        }
    }, [mapContainer, map]);

    return (
        <>
            <div className="map-container" ref={mapContainer}></div>
            {
                React.Children.map(children, child => {
                    if (React.isValidElement(child)) {
                        return React.cloneElement(child, {map});
                    }
                })
            }
        </>
    )
}

export default RouteMap