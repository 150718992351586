import {
    IonPage,
    IonInput,
    IonContent,
    IonButton,
    IonLabel,
    IonItem,
    IonTitle,
    useIonLoading
} from "@ionic/react"
import { Link, useHistory } from 'react-router-dom'
import { useRef } from "react"
import { toast } from "../../toast"
import { Profile } from '../../data/profile'
import { Carrier } from "../../data/carrier"

const Register: React.FC = () => {
    const history = useHistory()

    const emailRef = useRef('')
    const firstName = useRef('')
    const lastName = useRef('')
    const userName = useRef('')
    const phoneNumber = useRef('')
    const passwordRef = useRef('')
    const confirPasswordRef = useRef('')

    const [loading, dismissLoading] = useIonLoading()


    function register() {

        // email check
        // valid format: 
            // (any besides spaces) at (word chars any lenght) dot (word chars two or three)
        if (!emailCheck()) return toast('Email is invalid', 3000)
        
        // first and last name check
        // valid format:
            // (word chars, at least on, including dotted chars) (OPTIONAL space or dash separated word chars, same as first)
        if (!firstNameCheck()) return toast('First name is invalid', 3000)
        if (!lastNameCheck()) return toast('Last name is invalid', 3000)
        
        // username check
        // valid format:
            // (word chars with dotted chars and numbers can be separated by dash)
        if (!userNameCheck()) return toast('Username is invalid', 3000)

        // phonenumber check
        // valid format:
            // 
        if (!phoneNumberCheck()) return toast('Number is invalid', 3000)

        // password match check
        if (!passwordCheck()) return toast('Passwords do not match', 3000)

        const userData = new Profile(
            emailRef.current, 
            firstName.current,
            lastName.current, 
            userName.current, 
            phoneNumber.current,
            new Carrier()
            ).data()

        loading('Creating account', undefined, 'crescent')

            // fetch('http://localhost:5001/transport-mobile-app/us-central1/webApi/newUser', {
            fetch(process.env.REACT_APP_FIREBASE_FUNCTIONS_WEBAPI_URL + 'newUser', {
            method: 'POST',
            // mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user: userData,
                secret: passwordRef.current
            })
        })
            .then(async (res) => {
                if (res.ok) {

                    emailRef.current =''
                    firstName.current = ''
                    lastName.current = ''
                    userName.current = ''
                    phoneNumber.current = ''
                    passwordRef.current = ''
                    confirPasswordRef.current = ''

                    history.push('/login')
                }
                else {
                    const err = await res.json()
                    // console.debug(err)
                    toast(`Error: ${err.error}`, 3000)
                }
            })
            .catch((err) => {
                console.debug(err)
                toast(`Someting went wrong. ${err}`, 5000)
            })
            .finally(() => dismissLoading())
    }

    const emailCheck = () => /^[^\s]+@[a-zA-Z]+\.[a-zA-Z]{2,3}$/.test(emailRef.current)
    const firstNameCheck = () => /^([a-zA-ZüõöäÜÕÖÄ]([-\s][a-zA-ZüõöäÜÕÖÄ]+)*)+$/.test(firstName.current)
    const lastNameCheck = () => /^([a-zA-ZüõöäÜÕÖÄ]([-\s][a-zA-ZüõöäÜÕÖÄ]+)*)+$/.test(lastName.current)
    const userNameCheck = () => /^([a-zA-ZüõöäÜÕÖÄ0-9]([-][a-zA-ZüõöäÜÕÖÄ0-9]+)*)+$/.test(userName.current)
    const phoneNumberCheck = () => /^((\+)?(\d{1,15}))$/.test(phoneNumber.current)
    const passwordCheck = () => passwordRef.current === confirPasswordRef.current

    return (
        <IonPage>
            <IonContent className='ion-padding'>
                
                <IonTitle className='ion-text-uppercase ion-text-center'>Sign Up</IonTitle>
                <div>
                    <IonItem>
                        <IonLabel position="floating">Email</IonLabel>
                        <IonInput
                            type='email'
                            value={emailRef.current} 
                            onIonChange={e => emailRef.current = e.detail.value! }
                            placeholder='name@mail.com'
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">First name</IonLabel>
                        <IonInput
                            type='text'
                            value={firstName.current} 
                            onIonChange={e => firstName.current = e.detail.value!}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">Last name</IonLabel>
                        <IonInput
                            type='text'
                            value={lastName.current} 
                            onIonChange={e => lastName.current = e.detail.value!}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">Username</IonLabel>
                        <IonInput
                            type='text'
                            value={userName.current} 
                            onIonChange={e => userName.current = e.detail.value!}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">Phone number</IonLabel>
                        <IonInput
                            type='text'
                            value={phoneNumber.current} 
                            onIonChange={e => phoneNumber.current = e.detail.value!}
                            placeholder='+12355123123'
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">Password</IonLabel>
                        <IonInput
                            type='password' 
                            value={passwordRef.current} 
                            onIonChange={e => passwordRef.current = e.detail.value!}
                        />
                    </IonItem>
                    <IonItem className="ion-margin-bottom">
                        <IonLabel position='floating'>Confirm password</IonLabel>
                        <IonInput 
                            type='password'
                            value={confirPasswordRef.current}
                            onIonChange={e => confirPasswordRef.current = e.detail.value!}
                        />
                    </IonItem>
                    <IonButton
                        expand="block"
                        onClick={register}
                    >Sign Up</IonButton>
                </div>
                <p className='ion-text-center'>
                    Already have an account? <Link to='/login' style={{ textDecorationLine: 'none'}}>Sign In</Link>
                </p>
            </IonContent>
        </IonPage>
    )
}

export default Register
