import { IonPage, IonContent, IonLabel, IonHeader, IonToolbar, IonButton, IonTitle, IonGrid, IonRow, IonItem, IonFooter, IonButtons, useIonAlert, IonTextarea, IonRange, useIonLoading } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { toast } from '../../../toast';
import { User } from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';
import { Feedback } from '../../../data/feedback';
import { Order } from '../../../data/order';
import { ExtendedFirestoreInstance } from 'react-redux-firebase';


const NewFeedbackView: React.FC<{
  onDismiss: () => void,
  firestore: ExtendedFirestoreInstance,
  user: User
  order: {
    id: string,
    data: Order
  }
}> = ({ onDismiss, user, order }) => {

  const [present, dismiss] = useIonAlert();
  const [targetId, setTargetId] = useState('')
  const [role, setRole] = useState<'carrier' | 'sender'>('carrier')
  const [loading, dismissLoading] = useIonLoading()

  const rating = useRef<number>(5)
  const comment = useRef('');

  useEffect(() => {
    // Role determines who the feedback is given to.
    if (order.data.carrierId === user.uid) {
      setRole('sender')
      setTargetId(order.data.creatorId)
    }
    else
      setTargetId(order.data.carrierId!)
  })

  useEffect(() => {
    return () => dismissLoading()
  })

  const handleForm = () => {
    loading('Sending feedback...', 7000, 'crescent')

    const feedback = new Feedback(
      role,
      rating.current,
      comment.current,
      {
        displayName: user?.displayName!,
        id: user?.uid
      },
      targetId,
      order.id,
      Timestamp.now(),
    ).data()

    fetch(process.env.REACT_APP_FIREBASE_FUNCTIONS_WEBAPI_URL + 'newFeedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ feedback: feedback })
    })
      .then(res => res.json())
      .then(() => toast("Thank you for your feedback!"))
      .catch((e) => toast(e))
      .finally(() => {
        dismissLoading()
        onDismiss()
      })
  }

  
  return (
    <IonPage>

      <IonHeader>
        <IonToolbar>
          <IonTitle>Feedback</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() =>
              present({
                cssClass: 'my-css',
                header: `Are you sure you don't want to give feedback?`,
                message: 'Feedback is valluable both for carriers and senders.',
                buttons: [
                  { text: 'YES', handler: () => onDismiss() },
                  { text: 'NO', handler: () => { } },
                ],
                onDidDismiss: () => dismiss(),
              })
            }
            >Cancel</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={true}>
        <IonGrid>
          <IonRow className='ion-padding-top'>
            <IonTitle className='ion-text-center' size='small' text-wrap>
              How would you rate this {role}?
            </IonTitle>
          </IonRow>
          <IonRow>
            <IonRange min={1} max={10} step={1} value={5} snaps color="primary"
              onIonChange={e => rating.current = +e.detail.value!}>
              <IonLabel slot='start'>1</IonLabel>
              <IonLabel slot='end'>10</IonLabel>
            </IonRange>

          </IonRow>

          <IonItem>
            <IonLabel position='stacked'>Comment</IonLabel>
            <IonTextarea
              autoGrow
              maxlength={300}
              placeholder='Please leave a comment about how this delivery went'
              value={comment.current}
              onIonChange={e => comment.current = e.detail.value!} />
          </IonItem>


        </IonGrid>

        <IonFooter>
          <IonToolbar>
            <IonButton
              className='ion-padding-horizontal '
              slot='primary'
              onClick={() => handleForm()}
            >Send feedback</IonButton>
          </IonToolbar>
        </IonFooter>

      </IonContent>
    </IonPage>
  )
}


export default NewFeedbackView