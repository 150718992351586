import React, { useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonList,
  IonItem,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  useIonModal,
  IonText,
  IonLabel
} from "@ionic/react";
import CarrierRecommendation from "./CarrierRecommendation";
import { PresentCarrier } from "../../../../data/presentCarrier";
import CarrierItem from "./CarrierItem";
import { ExtendedFirestoreInstance } from "react-redux-firebase";

const SuitableCarriers: React.FC<{
  onDismiss: () => void;
  carriers: PresentCarrier[];
  orderId: string,
  firestore: ExtendedFirestoreInstance,
  debugLog: string
}> = ({ onDismiss, carriers, orderId, firestore, debugLog }) => {


  const [selectedCarrier, setSelectedCarrier] = useState<PresentCarrier | null>(null);
  const [presentCarrier, dismissCarrier] = useIonModal(CarrierItem, {
    carrier: selectedCarrier,
    orderId,
    onDismiss: () => dismissCarrier(),
    onComplete: () => {
      dismissCarrier();
      onDismiss();
    },
    firestore
  });

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonButtons slot="start">
                    <IonButton
                      color="danger"
                      fill="solid"
                      shape="round"
                      onClick={() => onDismiss()}
                    >
                      Cancel
                    </IonButton>
                  </IonButtons>
                  <IonTitle class="ion-text-center" size="large">
                    Suitable Carriers
                  </IonTitle>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={true} style={{ height: '100%' }}>
        <IonList>
          {
            carriers ? carriers.length !== 0 ? (
              carriers.map((carrier, key) => {
                return (
                  <CarrierRecommendation
                    key={key}
                    showCarrier={() => {
                      setSelectedCarrier(carrier);
                      presentCarrier();
                    }}
                    carrier={carrier}
                  />
                );
              })
            ) : (
              <IonTitle>
                <h2>No suitable carriers</h2>
              </IonTitle>
            )
            :
            <h2>No Suitable carriers</h2>
          }
        </IonList>
        <IonItem>
          <IonLabel>Debug Log:</IonLabel>
        </IonItem>
        <IonItem>
          <IonText>
            <div dangerouslySetInnerHTML={{ __html: debugLog.replace(/\n/g, '<br>') }} />
          </IonText>
        </IonItem>
      </IonContent>
    </>
  );
};

export default SuitableCarriers;