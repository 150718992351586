import { IonButton, IonButtons, IonContent, IonHeader, IonItem, IonItemDivider, IonItemGroup, IonPage, IonTitle, IonToolbar } from "@ionic/react"
import React from "react"

const PackagingGuide: React.FC<{
  onDismiss: () => void,
}> = ({ onDismiss }) => {
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Packaging Guide</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onDismiss()}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonItemGroup>
          <IonItemDivider className='ion-padding-top'>
            <IonTitle className='ion-no-padding' color='dark'>Overall Guidelines</IonTitle>
          </IonItemDivider>
          <IonItem lines='none'>
            <p className='ion-no-margin'>
              Make sure that the packaging is durable enough to protect the contents of your parcel. If needed, use multiple layers of corrugated cardboard, paper or wrapping plastic.
              You can use carton boxes and crates, depending on the size on the pacrel. Packaging must be of appropriate size: not too big and not too small.
              We advise you not to reuse packages, as they might get less durable with each use. <br/>
              Remember that the proper packaging is also needed so that the carriers vehicle won't get damaged during delivery, e.g., if you send objects that are sharp, made of glass, etc.
            </p>
          </IonItem>

          <IonItemDivider className='ion-padding-top'>
            <IonTitle className='ion-no-padding' color='dark'>Fragile Items</IonTitle>
          </IonItemDivider>
          <IonItem lines='none'>
            <p className='ion-no-margin'>
              If you are sending fragile items, check the “fragile package” checkbox, so that the carrier would make sure to handle them with care. Additional fees might occur when transporting fragile items.
            </p>
          </IonItem>

          <IonItemDivider className='ion-padding-top'>
            <IonTitle className='ion-no-padding' color='dark'>Damaged Items</IonTitle>
          </IonItemDivider>
          <IonItem className='ion-padding-bottom' lines='none'>
            <p className='ion-no-margin'>
              If the package you have just received is damaged, let us know about it so that we could contact the sender and the carrier responsible for the delivery, and compensate you accordingly.
              It is always best to check that the contents of the parcel are intact as soon as it has arrived, preferably in front of the carrier, so that they could confirm its state at the moment of delivery.
              It is possible to fire a complaint within 14 days after recieving the package.
            </p>
          </IonItem>
        </IonItemGroup>
      </IonContent>
    </IonPage>
  )
}

export default PackagingGuide