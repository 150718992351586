import { IonButton, IonCol, IonGrid, IonHeader, IonItem, IonRow, IonTitle, IonToolbar, IonLoading, IonIcon, IonButtons, IonToast } from "@ionic/react"
import React, { useRef, useState, useEffect } from "react"
import { trashOutline } from 'ionicons/icons';
import { toast } from "../../../../toast";

// See https://developers.google.com/maps/documentation/javascript/react-map

interface RouteSetupMarkerProps extends google.maps.MarkerOptions {
    onDragStart?: (e: google.maps.MapMouseEvent) => void,
    onDragEnd?: (e: google.maps.MapMouseEvent) => void,
    onClick?: (e: google.maps.MapMouseEvent) => void,
}

const RouteSetupMarker: React.FC<RouteSetupMarkerProps> = (options: RouteSetupMarkerProps) => {
    const [marker, setMarker] = useState<google.maps.Marker>();

    useEffect(() => {
        if (!marker) {
            setMarker(new google.maps.Marker());
        }
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        }
    }, [marker]);

    useEffect(() => {
        if (marker) {
            marker.setOptions(options);
            ['click', 'dragend', 'dragstart'].forEach(eventName => google.maps.event.clearListeners(marker, eventName));
            if (options.onClick) {
                marker.addListener('click', options.onClick);
            }
            if (options.onDragEnd) {
                marker.addListener('dragend', options.onDragEnd);
            }
            if (options.onDragStart) {
                marker.addListener('dragstart', options.onDragStart);
            }
        }
    }, [marker, options]);

    return null;
};

const RouteSetupPolyline: React.FC<google.maps.PolylineOptions> = (options: google.maps.PolylineOptions) => {
    const [polyline, setPolyline] = useState<google.maps.Polyline>();

    useEffect(() => {
        if (!polyline) {
            setPolyline(new google.maps.Polyline());
        }
        return () => {
            if (polyline) {
                polyline.setMap(null);
            }
        }
    }, [polyline]);

    useEffect(() => {
        if (polyline) {
            polyline.setOptions(options);
        }
    }, [polyline, options]);

    return null;
};

interface RouteSetupMapProps extends google.maps.MapOptions {
    onClick?: (e: google.maps.MapMouseEvent) => void,
}

const RouteSetupMap: React.FC<RouteSetupMapProps> = ({
    onClick,
    children,
    ...options
}) => {
    const mapContainer = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();

    useEffect(() => {
        if (mapContainer.current && !map) {
            setMap(new google.maps.Map(mapContainer.current, options));
        }
    }, [mapContainer, map]);

    useEffect(() => {
        if (map) {
            ['click'].forEach(eventName => google.maps.event.clearListeners(map, eventName));
            if (onClick) {
                map.addListener('click', onClick);
            }
        }
    }, [map, onClick]);

    return <>
        <div className="map-container" ref={mapContainer}></div>
        {React.Children.map(children, child => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child, { map });
            }
        })}
    </>;
};

interface RouteSetupState {
    waypoints: google.maps.LatLng[],
    route: google.maps.LatLng[] | null,
    currentIndex: number,
    fromAddress?: string,
    toAddress?: string,
};

const RouteSetup: React.FC<{
    dismiss: () => void,
    saveRoute: (data: RouteSetupState) => void
}> = ({ dismiss, saveRoute }) => {

    const initialMapOptions = {
        center: { lat: 58.6454381, lng: 25.4247266 },
        zoom: 7.88,
        disableDefaultUI: true,
        clickableIcons: false,
    };
    const [setupState, setSetupState] = useState<RouteSetupState>({
        waypoints: [],
        route: null,
        currentIndex: 0,
        fromAddress: undefined,
        toAddress: undefined
    });
    const [toastIsVisible, setToastVisible] = useState(true);

    useEffect(() => {
        setToastVisible(setupState.waypoints.length <= 0);
        return () => setToastVisible(false);
    }, [setupState]);

    const onMapClick = (event: google.maps.MapMouseEvent) => {
        const latLng = event.latLng!;
        if (setupState.waypoints.length <= 0) {
            setSetupState({
                ...setupState,
                waypoints: [latLng],
                currentIndex: 0,
                fromAddress: undefined,
                toAddress: undefined
            });
        } else if (setupState.waypoints.length >= 10) {
            toast("No more than 10 waypoints allowed!");
        } else {
            setSetupState({
                ...setupState,
                waypoints: [
                    ...setupState.waypoints.slice(0, setupState.currentIndex + 1),
                    latLng,
                    ...setupState.waypoints.slice(setupState.currentIndex + 1)
                ],
                route: null,
                currentIndex: setupState.currentIndex + 1,
                fromAddress: setupState.fromAddress,
                toAddress: undefined
            });
        }
    };

    const selectWaypoint = (index: number) => {
        setSetupState(oldState => ({ ...oldState, currentIndex: index }));
    };

    const onWaypointDragged = (index: number, event: google.maps.MapMouseEvent) => {
        const latLng = event.latLng!;
        const newWaypoints = [
            ...setupState.waypoints.slice(0, index),
            latLng,
            ...setupState.waypoints.slice(index + 1)
        ];

        setSetupState(oldState => ({
            ...oldState,
            waypoints: newWaypoints,
            route: null,
            fromAddress: index === 0 ? undefined : oldState.fromAddress,
            toAddress: index === oldState.waypoints.length - 1 ? undefined : oldState.toAddress
        }));
    };

    const deleteCurrentWaypoint = () => {
        const newWaypoints = [
            ...setupState.waypoints.slice(0, setupState.currentIndex),
            ...setupState.waypoints.slice(setupState.currentIndex + 1)
        ];

        setSetupState(oldState => ({
            ...oldState,
            waypoints: newWaypoints,
            currentIndex: oldState.currentIndex > 0 && oldState.currentIndex >= oldState.waypoints.length - 1
                ? oldState.currentIndex - 1
                : oldState.currentIndex,
            route: null,
            fromAddress: newWaypoints.length > 0 ? oldState.fromAddress : undefined,
            toAddress: newWaypoints.length > 1 ? oldState.toAddress : undefined
        }));
    };

    const pendingDirections = () => setupState.route === null && setupState.waypoints.length >= 2;

    useEffect(() => {
        if (pendingDirections()) {
            const service = new google.maps.DirectionsService();
            service.route(
                {
                    origin: setupState.waypoints[0],
                    destination: setupState.waypoints.slice(-1)[0],
                    waypoints: setupState.waypoints.slice(1, -1).map(
                        latLng => ({ location: latLng })),
                    travelMode: google.maps.TravelMode.DRIVING,
                },
                (directionsResult, status) => {
                    if (status !== google.maps.DirectionsStatus.OK || directionsResult === null) {
                        console.error("Directions error:", status);
                        setSetupState(oldState => ({ ...oldState, route: [] }));
                        toast("Route not possible", 4000);
                    } else {
                        console.debug("Directions result:", status, directionsResult);
                        const coords: google.maps.LatLng[] = [];
                        directionsResult.routes[0].legs.forEach((leg) => {
                            leg.steps.forEach((step) => {
                                step.path.forEach((c) => {
                                    if (!c.equals(coords[coords.length - 1])) {
                                        coords.push(c);
                                    }
                                });
                            });
                        });
                        setSetupState(oldState => ({
                            ...oldState,
                            route: coords,
                            fromAddress: directionsResult.routes[0].legs[0].start_address,
                            toAddress: directionsResult.routes[0].legs.slice(-1)[0].end_address
                        }));
                    }
                }
            );
        }
    }, [setupState]);

    const haveValidRoute = () => setupState.route !== null && setupState.route.length > 0 && setupState.waypoints.length > 1;

    return <>
        <IonLoading isOpen={pendingDirections()} message='Calculating exact route, please wait...' />
        <IonToast position='bottom' message='Tap the map to add first waypoint' isOpen={toastIsVisible} />
        <IonHeader>
            <IonToolbar>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonItem lines='none'>
                                <IonButton
                                    color='danger'
                                    fill='solid'
                                    shape='round'
                                    onClick={() => {
                                        setToastVisible(false);
                                        dismiss();
                                    }}
                                >Cancel</IonButton>
                                <IonTitle className='ion-text-middle'>Create Route</IonTitle>
                                <IonButton disabled={!haveValidRoute()} onClick={() => {
                                    saveRoute(setupState);
                                    dismiss();
                                }}>Finish</IonButton>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonToolbar>
        </IonHeader>
        <RouteSetupMap {...initialMapOptions} onClick={onMapClick}>
            {setupState.waypoints.map((waypoint, index) => {
                return <RouteSetupMarker
                    key={'waypoint_marker_' + index}
                    position={waypoint}
                    icon={{
                        path: "M 11.641666,-23.283334 C 11.641666,-16.853819 4.2487828,-4.248783 0,0 -4.2472904,-4.247291 -11.641667,-16.853819 -11.641667,-23.283334 -11.641667,-29.712848 -6.4295148,-34.925 0,-34.925 c 6.4295148,0 11.641666,5.212152 11.641666,11.641666 z",
                        labelOrigin: new google.maps.Point(0, -20),
                        fillOpacity: 0.75,
                        fillColor: index === setupState.currentIndex ? "red" : "blue",
                        strokeColor: "black",
                        strokeWeight: 1,
                    }}
                    draggable={true}
                    onClick={() => { selectWaypoint(index); }}
                    onDragEnd={event => { onWaypointDragged(index, event); }}
                    onDragStart={() => { selectWaypoint(index); }}
                    label={(index + 1).toString()}
                    title={`Waypoint ${index + 1} / ${setupState.waypoints.length}`}
                />;
            })}
            {setupState.route !== null
                && setupState.route.length > 0
                && <RouteSetupPolyline
                    clickable={false}
                    path={setupState.route}
                    strokeColor={"blue"}
                    strokeOpacity={0.5}
                />}
        </RouteSetupMap>
        <IonToolbar>
            <IonTitle size='small'>
                {setupState.waypoints.length <= 0 ? "No waypoints" : `Waypoint ${setupState.currentIndex + 1} / ${setupState.waypoints.length}`}
            </IonTitle>
            <IonButtons slot='end'>
                <IonButton disabled={setupState.waypoints.length <= 0} onClick={deleteCurrentWaypoint}>
                    <IonIcon slot="icon-only" icon={trashOutline} />
                </IonButton>
            </IonButtons>
        </IonToolbar>
    </>;
    };
    
    export default RouteSetup;