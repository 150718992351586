import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonNote, IonPage, IonRouterOutlet, IonRow, IonSegment, IonSegmentButton, IonToolbar, useIonModal } from "@ionic/react"
import { useEffect, useState } from "react"
import FeedbackView from "./FeedbackView"
import HistoryView from "./HistoryView"
import DeliveriesView from "./DeliveriesView"
import { Redirect, Route, useHistory } from "react-router"
import { useSelector } from "react-redux"
import { Profile } from "../../../data/profile"
import CarrierSetup from "./components/CarrierSetup"
import { isLoaded, useFirestore } from "react-redux-firebase"



const CarrierPage: React.FC = () => {
    const [page, setPage] = useState('deliveries')
    const {auth, profile}: {auth: any, profile: Profile} = useSelector((state: any) => state.firebase)
    const firestore = useFirestore()

    const [carrierSetup, dismissSetup] = useIonModal(CarrierSetup, {
        onDismiss: () => dismissSetup(),
        auth,
        firestore
    })
    const history = useHistory()

    useEffect(() => {}, [profile.carrier])

    if (!isLoaded(profile)) {
        return <IonPage>Loading...</IonPage>
    }
    else if (profile.carrier === null || (profile.carrier && profile.carrier.routes.length === 0)) {
        return (
            <IonPage>
                <IonGrid className='ion-text-center'>
                    <IonRow className='ion-padding'>
                        <IonCol>
                            <IonNote>Carrier View unaccessible without routes</IonNote>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-padding'>
                        <IonCol>
                            <IonButton
                                shape='round'
                                onClick={() => carrierSetup()}
                            >Carrier Setup</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonPage>
        )
    }

    return (
    <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonSegment value={page} onIonChange={(e) => {
                    setPage(e.detail.value!)
                    history.push(`/carrier/${e.detail.value}`)
                }}>
                    <IonSegmentButton value='deliveries'>Deliveries</IonSegmentButton>
                    <IonSegmentButton value='history'>History</IonSegmentButton>
                    <IonSegmentButton value='feedback'>Feedback</IonSegmentButton>
                </IonSegment>
            </IonToolbar>
        </IonHeader>
        <IonContent fullscreen={false}>
                {/* <IonContent fullscreen={false}> */}
                    <IonRouterOutlet basePath='carrier'>
                        <Redirect from='/carrier' exact to='/carrier/deliveries'/>
                        <Route path='/carrier/deliveries' component={DeliveriesView}/>
                        <Route path='/carrier/history' component={HistoryView}/>
                        <Route path='/carrier/feedback' component={FeedbackView}/>
                    </IonRouterOutlet>
                {/* </IonContent> */}
        </IonContent>
    </IonPage>
)}

export default CarrierPage