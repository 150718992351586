import { IonApp, IonLabel, IonLoading, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import React from "react";
import {ReactElement} from "react";
import {Route} from "react-router";
import { Wrapper, Status } from "@googlemaps/react-wrapper"

import Welcome from './pages/startup/Welcome';
import Login from './pages/startup/Login';
import Register from './pages/startup/Register';
import AppleSignIn from './pages/startup/AppleSignIn';
import AddRoutes from './pages/startup/AddRoutes';

import Navigation from './pages/dashboard/Navigation';
import SenderPage from './pages/dashboard/sender';
import CarrierPage from './pages/dashboard/carrier';
import ProfilePage from './pages/dashboard/profile';
import NewOrderView from './pages/dashboard/sender/NewOrderView';
import EmailVerification from './components/EmailVerification';


const App: React.FC = () => {

  const wrapperRender = (status: Status): ReactElement => {
      if (status === Status.LOADING) return <IonLoading isOpen={true} />;
      if (status === Status.FAILURE) {
          console.error("Error loading Google Maps API");
          return <IonLabel>Error loading Google Maps API</IonLabel>;
      }
      return <IonLabel>Done</IonLabel>;
  }

  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  return (
    <IonApp>
      <Wrapper apiKey={apiKey ? apiKey : ""} version={"weekly"} libraries={["places"]} render={wrapperRender}>
        <IonReactRouter>

          <IonRouterOutlet>

          <Route exact path='/' component={Welcome}/>
          <Route exact path='/login' component={Login}/>
          <Route exact path='/register' component={Register}/>
          <Route exact path='/AppleSignIn' component={AppleSignIn}/>
          <Route exact path='/AddRoutes' component={AddRoutes}/>
          <Route exact path='/verification' component={EmailVerification}/>

            {/* Main navigation */}

              <Navigation>

                  <Route path='/sender' component={SenderPage}/>
                  <Route path='/carrier' component={CarrierPage}/>
                  <Route path='/profile' component={ProfilePage}/>

              </Navigation>

                  <Route exact path='/sender/newOrder' component={NewOrderView}/>


            {/* Main navigation end */}


          </IonRouterOutlet>

        </IonReactRouter>
      </Wrapper>
    </IonApp>
  )
};

export default App;
