import { IonLoading } from "@ionic/react"
import { useEffect, useState } from "react"
import { toast } from "../../../../toast"
import RouteMap from "./RouteMap"
import RouteMarker from "./RouteMarker"
import RoutePolyline from "./RoutePolyline"




interface RouteState {
    waypoints: google.maps.LatLng[],
    route: google.maps.LatLng[] | null,
    currentIndex: number,
};

interface RouteItemState {
    disabled?: boolean,
    route? : RouteState
}

const RouteItem: React.FC<RouteItemState> = ({
    disabled = false,
    route = {
        waypoints: [],
        route: null,
        currentIndex: 0
    }
}) => {

    const [itemState, setItemState] = useState(route)
    const pendingDirections = () => !itemState.route && route.waypoints.length >= 2;

    useEffect(() => {
        console.debug('itemState: ', itemState)
        if (pendingDirections()) {
            const service = new google.maps.DirectionsService();
            service.route(
                {
                    origin: itemState.waypoints[0],
                    destination: itemState.waypoints.slice(-1)[0],
                    waypoints: itemState.waypoints.slice(1, -1).map(
                        latLng => ({ location: latLng })),
                    travelMode: google.maps.TravelMode.DRIVING,
                },
                (directionsResult, status) => {
                    if (status !== google.maps.DirectionsStatus.OK || directionsResult === null) {
                        console.error("Directions error:", status);
                        setItemState(oldState => ({...oldState, route: []}));
                        toast("Route not possible", 4000);
                    } else {
                        console.debug("Directions result:", status, directionsResult);
                        setItemState(oldState => ({...oldState, route: directionsResult.routes[0].overview_path}));
                    }
                }
            );
        }
    }, [itemState]);


    const newBounds = () => {
        const first = itemState.waypoints[0]
        const last = itemState.waypoints[itemState.waypoints.length - 1]

        if (first.lat() < last.lat() && first.lng() > last.lng()) return new google.maps.LatLngBounds(last, first)
        else if (first.lat() < last.lat() && first.lng() < last.lng()) return new google.maps.LatLngBounds(first, last)
        else if (first.lat() > last.lat() && first.lng() > last.lng()) return new google.maps.LatLngBounds(last, first)
        else return new google.maps.LatLngBounds(first, last)
    }

    return (
        <>
            <IonLoading isOpen={pendingDirections()} message='Loading route, please wait...'/>
            <RouteMap disabled={disabled} bounds={newBounds()}>
                {
                    itemState.waypoints.map((waypoint, index) => {
                        console.debug('waypoint: ', index, waypoint)
                        
                        return <RouteMarker
                            key={'waypoint_marker_' + index}
                            position={waypoint}
                            label={(index + 1).toString()}
                        />
                    })
                }
                {
                    (() => {
                        if (itemState.route && itemState.route.length > 0) {
                            return <RoutePolyline 
                                clickable={false}
                                path={route.route}
                                strokeColor={"blue"}
                                strokeOpacity={0.5}
                            />
                        }
                    })()
                }
            </RouteMap>
        </>
    )
}

export default RouteItem