import React from "react";
import {
  IonCard,
  IonCardSubtitle,
  IonCardContent,
  IonGrid,
  IonCardHeader,
  IonRow,
  IonCardTitle,
  IonCol,
} from "@ionic/react";
import { Feedback } from "../data/feedback";

const FeedbackItem: React.FC<{
  feedback: Feedback
}> = ({ feedback }) => {

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardSubtitle>
          <IonGrid className='ion-no-padding'>
            <IonRow className='ion-justify-content-between'>
              <IonCol size='auto'>{feedback.author.displayName}</IonCol>
              <IonCol size='auto'>{new Date(feedback.date.seconds * 1000).toLocaleDateString()}</IonCol>
            </IonRow>
          </IonGrid>
        </IonCardSubtitle>
        <IonCardTitle>Order ID: {feedback.orderId}</IonCardTitle>
      </IonCardHeader>

      <IonCardContent color='dark'>
        {feedback.comment}<br />
        <strong>Rating: {feedback.rating}/10</strong>
      </IonCardContent>
    </IonCard>
  );
};

export default FeedbackItem;
